import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { MdOutlineLocalPrintshop } from '../../assets/icons/icons'

export default function PagePrivacy(){
    const [page, setPage] = useState([]);
        useEffect(() => {
            const fetchPage = async () => {
                const response = await fetch('/api/pages/slug/'+'privacy-policy');
                const json = await response.json();
                if (response.ok) {
                    setPage(json)
                    console.log('json', json);
                }
    
            }
            fetchPage()
        }, []);
    return(
        <>
        <Navbar/>
        <section className="bg-half-130 d-table bg-light w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h3 className="title mb-3">Privacy Policy</h3>
                            <p className="para-desc mx-auto text-muted mb-0">
                                Understand how Operation Track collects, uses, and protects your information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="card shadow rounded border-0">
                            <div className="card-body">
                                <h5 className="card-title">Privacy Policy for Operation Track</h5>
                                {page?.content && (
  <div
    className="text-muted fade-in"
    dangerouslySetInnerHTML={{ __html: page.content }}
  />
)}
                                <Link to="#" onClick={() => window.print()} className="btn btn-soft-primary d-print-none mt-3">
                                    <MdOutlineLocalPrintshop /> Print
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}
